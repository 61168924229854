module.exports = [{
      plugin: require('C:/Users/xshau/Documents/Websites (temp)/stellar-diz/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"StellarDev Website","short_name":"StellarDev","start_url":"/","background_color":"#17171E","theme_color":"#3069EC","display":"minimal-ui","icon":"src/images/StellarLogoWhite.svg"},
    },{
      plugin: require('C:/Users/xshau/Documents/Websites (temp)/stellar-diz/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('C:/Users/xshau/Documents/Websites (temp)/stellar-diz/node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Inter"],"urls":["fonts/Inter/inter.css"]}},
    },{
      plugin: require('C:/Users/xshau/Documents/Websites (temp)/stellar-diz/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
